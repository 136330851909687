import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { intl } from "i18n/provider";

const EditableTextFieldForm = ({
  initialValue,
  label,
  onSubmit,
  isLoading,
}) => {
  const [isEditable, setIsEditable] = useState(true);
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);

  const handleInputClick = () => {
    setIsEditable(true);
    !isEditable && setValue(null);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (value.length > 4) {
      setIsEditable(false);
      onSubmit(value);
    } else {
      setIsValid(false);
      return;
    }
  };

  // const handleOnBlur = () => {
  //   if (value.length > 6) {
  //     setIsEditable(false);
  //     setIsValid(true);
  //   } else {
  //     setIsValid(false);
  //   }
  // };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginTop={2}
    >
      <form onSubmit={handleSubmit} style={{ width: "300px" }}>
        <div onClick={handleInputClick}>
          {isEditable ? (
            <TextField
              value={value}
              type="text"
              onChange={handleChange}
              label={label}
              fullWidth
              required
              autoFocus
              variant="outlined"
              margin="normal"
              error={!isValid}
              helperText={
                !isValid
                  ? intl.formatMessage({
                      id: "validNoErrMsg",
                    })
                  : ""
              }
            />
          ) : (
            <Box
              className="readonly-input"
              style={{
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: "1rem",
                }}
              >
                {label}
              </Typography>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", color: "#333", margin: 0 }}
              >
                {value}
              </Typography>
            </Box>
          )}
        </div>
        <LoadingButton
          id="mada-bills-search-btn"
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
          style={{ marginTop: "16px" }}
        >
          {intl.formatMessage({ id: "search" })}
        </LoadingButton>
      </form>
    </Box>
  );
};

export default EditableTextFieldForm;

import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import Badge from "../../ui/Badge/Badge";

import { dataLanguage } from "../../../i18n/translateRequest";
import translate from "../../../i18n/translate";

const GroupCompaniesBundleCard = ({
  columnStyle,
  item,
  onTypeClick,
  index,
  collapseRef,
  isDarkTheme,
  moveCard,
  id,
}) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "cards",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "cards",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;

  return (
    <div
      className={`${columnStyle} mt-3`}
      data-handler-id={handlerId}
      style={{ opacity }}
    >
      <div className="card charge-card" ref={ref}>
        <div
          style={{ cursor: "pointer" }}
          data-placement="top"
          onClick={() => onTypeClick(item)}
        >
          <div className="position-relative">
            <img alt={item.title} src={item.url} width={"100%"}></img>
            {(item.renew === "True" || item.renew === "true") && (
              <Badge text={translate("Renewable")}></Badge>
            )}
          </div>
        </div>
      </div>
      <div className={`${isDarkTheme ? "dark-card" : "card"}`}>
        <div className="text-center" id="headingOne">
          <h5 className="mb-0 mx-">
            <div
              className="link-main details"
              style={{ fontWeight: "bolder" }}
              data-toggle="collapse"
              data-target={"#" + index}
              aria-expanded="true"
              aria-controls={index}
            >
              <small>{translate("Details")}</small>
            </div>
          </h5>
        </div>

        <div
          id={index}
          className="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordion"
          ref={(el) => (collapseRef.current[index] = el)}
        >
          <div className="card-body">
            <small>
              {dataLanguage(item, "ar_title", "en_title", "he_title")}
            </small>
            <br />
            <small>{dataLanguage(item, "ar_des", "en_des", "he_des")}</small>
            <br />
            <p style={{ textAlign: "center" }}>{item.PID}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupCompaniesBundleCard;

// src/components/ConfirmModal.js

import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Card,
  CardMedia,
  Typography,
} from "@mui/material";
import { intl } from "i18n/provider";
import translate from "i18n/translate";
import JawwalPlusCard from "../jawwal/JawwalPlusCard";

const CardEmptyImage = ({ card }) => {
  return (
    <Card style={{ position: "relative", width: "200px" }}>
      <CardMedia
        component="img"
        alt=""
        image={card.url}
        title="Card Image"
        style={{ height: "100%" }}
      />
      <Typography
        style={{
          width: "calc(50% - 20px)",
          height: "70%",
          padding: 0,
          position: "absolute",
          top: "50%",
          left: "calc(50% + 12px)",
          transform: "translateY(-50%)",
          fontSize: "2rem",
          fontFamily: '"Montserrat", sans-serif',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        name="price"
      >
        {card.price}
      </Typography>
    </Card>
  );
};

const ConfirmModal = ({
  open,
  handleClose,
  selectedCards,
  phoneNumber,
  handleConfirm,
  totalAmount,
}) => {
  const language = localStorage.getItem("langCity");
  // const confirmationMessage = intl
  //   .formatMessage({ id: "confirmation_modal_msg" })
  //   .replace("{phone_number}", phoneNumber)
  //   .replace("{total_amount}", totalAmount);

  const formatCardDetails = (card, type) => {
    switch (type) {
      case "credit":
      case "creditEmpty":
        return intl
          .formatMessage({ id: "credit_charge_msg" })
          .replace("{amount}", card?.price);

      case "plus":
      case "Plus":
        return intl
          .formatMessage({ id: "plusJawwalDetailsCard" })
          .replace("{total_amount}", card?.amount)
          .replace("{minutes}", card?.minutes)
          .replace("{messages}", card?.messages)
          .replace("{gb}", card?.gb);

      default:
        return language === "en"
          ? card?.en_title || card?.en_des || card?.["en-des"]
          : card?.ar_title || card?.ar_des || card?.["ar-des"];
    }
  };
  const getCardImage = (card) => {
    if (card?.type?.toLowerCase() === "plus") {
      return <JawwalPlusCard bundle={card} />;
    } else if (card?.type === "creditEmpty") {
      return <CardEmptyImage card={card} />;
    } else {
      return <img src={card.url} alt="" style={{ width: "200px" }} />;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{translate("confirm")}</DialogTitle>
      <DialogContent>
        {selectedCards?.map(
          (card, index) =>
            card && (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "16px",
                  marginBottom: "16px",
                }}
              >
                {getCardImage(card)}
                <div>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {formatCardDetails(card, card.type)}
                  </Typography>
                </div>
              </div>
            )
        )}
        {/* <Typography variant="h6" style={{ marginTop: "16px" }}>
          {confirmationMessage}
        </Typography> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {translate("cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          {translate("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;

import React, { useState, useEffect, useRef } from "react";
import translate from "../../i18n/translate";
import {
  getSellerReports,
  updateTransaction,
} from "../../actions/reportsAction";
import { getDiscounts } from "../../actions/discountsAction";
import {
  getSellers,
  getTopUpAccounts,
} from "../../actions/sellerCreditsAction";
import "./report.css";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import Dropdown from "react-dropdown";
import "react-datepicker/dist/react-datepicker.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReportHeader from "./ReportHeader";
import { useIntl } from "react-intl";
// import Pagination from "@mui/material/Pagination";
import { getGamingCards } from "../../actions/gameAction";
import { isArray } from "lodash";
import Select from "react-select";
import Button from "../common/Button";
import Modal from "../common/Modal";

import UserTypesDropdown from "../../core/core-components/UserTypesDropdown/UserTypesDropdown";
import {
  formatSellerOptionsForSelect,
  formatTopUpAccountsOptionsForSelect,
} from "../../shared/utils/formatUtils";
import SaleReportTable from "../../core/core-components/SaleReportTable/SaleReportTable";
import { GET_SELLER_REPORTS } from "../../actions/types";
import DatePicker from "react-datepicker";
import CancelableInput from "core/core-components/CancelableInput/CancelableInput";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";
import {
  Grid,
  TextField,
  Typography,
} from "../../../node_modules/@mui/material/index";
import { conditionalReturn } from "shared/utils/utils";
import { makeStyles } from "../../../node_modules/@mui/styles/index";
import reportStyles from "./reportStyles";
import classNames from "../../../node_modules/classnames/index";
import { CARD_TYPES } from "shared/constants/constants";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { jsonParser } from "shared/utils/ppUtils";
import ApiRequest from "../../actions/ApiRequest";
import SelectSeller from "components/cards/SelectSeller";
import { cleanStorage } from "shared/utils/cleanStorage";

const transactionOptions = [
  { value: "success", label: "Success" },
  { value: "failed", label: "Failed" },
  { value: "proccessing", label: "Proccessing" },
];

const useStyle = makeStyles(reportStyles);

const Report = ({
  sellerReports,
  getSellerReports,
  discounts,
  getDiscounts,
  auth,
  getSellers,
  sellers,
  isDarktheme,
  updateTransaction,
  updateTransactionLoading,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const options = [
    { value: "", label: translate("Select Trans type") },
    { value: "topup", label: translate("topup") },
    { value: "cancelation", label: translate("cancelation") },
    { value: "add credits", label: translate("add credits") },
    { value: "sales", label: translate("sales") },
  ];

  const transStatusOptions = [
    { value: "", label: translate("Select Status") },
    { value: "success", label: translate("success") },
    { value: "failed", label: translate("failed") },
    { value: "pending", label: translate("pending") },
  ];

  const cardTypeOptions = [
    { value: "", label: translate("Select Card Type") },
    ...Object.values(CARD_TYPES),
  ];
  const sellerId = useSelector((state) => state.auth.user?.id);

  const now = new Date();

  const [dateTo, setDateTo] = useState(now);
  const [dateFrom, setDateFrom] = useState(now);
  const classes = useStyle();
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [transStatus, setTransStatus] = useState({
    value: "",
    label: translate("Select Status"),
  });
  const [transType, setTransType] = useState({
    value: "",
    label: translate("Select Trans type"),
  });
  const [provider, setProvider] = useState({
    value: "",
    label: translate("Select Provider"),
  });
  const [cardType, setCardType] = useState({
    value: "",
    label: translate("Select Card Type"),
  });
  const [sellersOptions, setSellerOptions] = useState([]);
  const [seller, setSeller] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState();
  const [settingLoading, setSettingLoading] = useState(false);
  const [transId, setTransId] = useState("");
  const [cardId, setCardId] = useState("");
  const [amount, setAmount] = useState("");
  const [location, setLocation] = useState("");
  const [renew, setAutoRenew] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [cancelRequest, setCancelRequests] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [autoRun, setAutoRun] = useState(false);
  const [disableAttr, setDisableAttr] = useState(false);
  const [isAdminSearching, setIsAdminSearching] = useState(false);

  const [loading, isLoading] = useState(false);

  const reqInterval = useRef(null);
  const [updateTransactionModal, setUpdateTransactionModal] = useState(false);
  const [updateTransactionStatus, setUpdateTransactionStatus] = useState({
    value: "",
    label: "Select Trans type",
  });
  const [updateTransactionData, setUpdateTransactionData] = useState({
    transid: "",
    value: "",
  });
  const [resellerId, setResellerId] = useState("");
  const [topUpAccountNo, setTopUpAccountNo] = useState();
  const topUpAccounts = useSelector((state) => state.credits.topUpAccounts);
  const intl = useIntl();
  const isAdminSeller = auth.user?.seller_type === "admin";
  const isReseller = auth.user?.seller_type === "reseller";
  const allSellers = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_SELLER_REPORTS,
      payload: [],
    });
    cleanStorage();
  }, [dispatch]);

  useEffect(() => {
    document.title = "Report | Phone Play";
    if (!discounts?.length) {
      getDiscounts();
    }
    try {
      getSellers(isReseller && sellerId).then(({ all_sellers: data }) => {
        if (isArray(data)) {
          allSellers.current = data;
          setSellerOptions(formatSellerOptionsForSelect(data));
        }
      });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellers?.length]);

  useEffect(() => {
    if (isAdminSeller) {
      dispatch(getTopUpAccounts());
      setSeller({ value: "", label: translate("Select all") });
    }
  }, [dispatch, isAdminSeller]);

  useEffect(() => {
    let providers = [];
    providers = discounts.map((discount) => ({
      value: discount.product,
      label: discount.product,
    }));
    providers = [
      { value: "", label: translate("Select Provider") },
      { label: "Peletalk", value: "peletalk" },
      ...providers,
    ];

    setCompanies(providers);

    return () => stopRequest();
  }, [discounts]);

  useEffect(() => {
    if (sellerReports.length) {
      setTotalCount(Number(sellerReports[0].total_count));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerReports]);

  useEffect(() => {
    if (autoRun) {
      setDisableAttr(true);
    } else {
      setDisableAttr(false);
    }
  }, [autoRun]);

  useEffect(
    function () {
      if (!allSellers.current) return;

      if (selectedUserType) {
        let sellersOptionsData = formatSellerOptionsForSelect(
          allSellers.current.filter((v) => v.type === selectedUserType?.value)
        );
        setSellerOptions(sellersOptionsData);
        setSeller(null);
      } else {
        setSellerOptions(formatSellerOptionsForSelect(allSellers.current));
        setSeller({ value: "", label: translate("Select all") });
      }
    },
    [selectedUserType]
  );

  const startRequest = () => {
    setIsAdminSearching(true);
    getSellerReports(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD"),
      phone,
      transType.value,
      transStatus.value,
      transId,
      cardId,
      cancelRequest,
      canceled,
      amount,
      renew,
      provider.value,
      cardType.value,
      "0",
      rowsPerPage,
      seller ? seller?.value : jsonParser(localStorage.currentUser).id,
      resellerId,
      topUpAccountNo?.value,
      location,
      "sales"
    );
    reqInterval.current = setInterval(() => {
      getSellerReports(
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(dateTo).format("YYYY-MM-DD"),
        phone,
        transType.value,
        transStatus.value,
        transId,
        cardId,
        cancelRequest,
        canceled,
        amount,
        renew,
        provider.value,
        cardType.value,
        "0",
        rowsPerPage,
        seller ? seller?.value : jsonParser(localStorage.currentUser).id,
        resellerId,
        topUpAccountNo?.value,
        location,
        "sales"
      );
    }, 5000);
  };

  const stopRequest = () => {
    clearInterval(reqInterval.current);
    setAutoRun(false);
    setIsAdminSearching(false);
  };

  const getReportData = (page = pageNumber, rowsCount = rowsPerPage) => {
    isLoading(true);
    getSellerReports(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD"),
      phone,
      transType.value,
      transStatus.value,
      transId,
      cardId,
      cancelRequest,
      canceled,
      amount,
      renew,
      provider.value,
      cardType.value,
      page,
      rowsCount,
      seller ? seller?.value : jsonParser(localStorage.currentUser).id,
      resellerId,
      topUpAccountNo?.value,
      location,
      "sales",
      note
    ).then(() => {
      isLoading(false);
    });
  };

  const handleSearch = () => {
    setPageNumber(1);
    getReportData("0", rowsPerPage);
  };

  const handleAdminSearch = () => {
    if (!isAdminSearching && autoRun) {
      startRequest();
      return;
    }
    stopRequest();
  };

  const onPageChange = (value) => {
    setPageNumber(value);
    getReportData(value, rowsPerPage);
    stopRequest();
  };

  const onRowsPerPageChange = (v) => {
    setPageNumber(1);
    setRowsPerPage(Number(v));
    if (sellerReports?.length) {
      getReportData("0", v);
    }
    stopRequest();
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: isDarktheme ? "#242526" : "",
      border: "1px solid #242526",
    }),
    menu: (provided) => ({
      ...provided,
      background: isDarktheme ? "#242526" : "",
      color: isDarktheme ? "white" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarktheme ? "white" : "",
    }),
  };

  function renderUserTypesDropdown() {
    return (
      <UserTypesDropdown
        sellersOptions={sellersOptions}
        selectedSeller={seller}
        selectedUserType={selectedUserType}
        onSelectUserType={(value) => setSelectedUserType(value)}
        disabled={disableAttr}
        onSelectSeller={(value) => {
          setSeller(value || { value: "", label: translate("Select all") });
        }}
      />
    );
  }
  const settingHandler = async (report, sellerId, newAmount) => {
    setSettingLoading(true);
    try {
      await ApiRequest.post(
        `ppt?transid=${report.transid}&sellerid=${sellerId}&amount=${newAmount}`
      );
    } catch (e) {
      alert(e);
    }
    setSettingLoading(false);
  };
  const updateTransactionStatusHandler = async (report, status) => {
    // setUpdateTransactionModal(false);
    // setSettingLoading(true);
    try {
      await updateTransaction({
        ...report,
        transId: report.transid,
        value: status,
      });
      await getReportData(pageNumber, rowsPerPage);
      // alert("Transaction status updated successfully");
    } catch (e) {
      alert(e);
    } finally {
      setSettingLoading(false);
    }
    // getReportData();
    // console.log(report);
  };

  function renderSaleReportTable() {
    return (
      <SaleReportTable
        count={totalCount}
        onRefresh={() => getReportData()}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        isLoading={loading}
        onEditClick={updateTransactionStatusHandler}
        // onEditClick={(report,newStatus) => {
        // setUpdateTransactionModal(true);
        // setUpdateTransactionData({
        //   ...updateTransactionData,
        //   value: report.status,
        //   transid: report.transid,
        // });
        // setUpdateTransactionStatus({
        //   label: report.status,
        //   value: report.status,
        // });
        // updateTransactionStatusHandler(report,newStatus);
        // }}
        title={translate("Sales Table")}
        page={pageNumber}
        rowsPerPage={rowsPerPage}
        totalText={translate("Total Sales")}
        onSettingClick={settingHandler}
        onLoadingSetting={settingLoading}
      />
    );
  }

  function resetAll() {
    setProvider({
      value: "",
      label: translate("Select Card Type"),
    });
    setCardType({
      value: "",
      label: translate("Select Card Type"),
    });
    setTransType({
      value: "",
      label: translate("Select Card Type"),
    });
    setTransStatus({
      value: "",
      label: translate("Select Card Type"),
    });
    setDateFrom(new Date());
    setDateTo(new Date());
    setCancelRequests(false);
    setCanceled(false);
    setAutoRenew(false);
    setAutoRun(false);
    setLocation("");
    setAmount("");
    setTransId("");
    setPhone("");
    setSelectedUserType("");
    setResellerId("");
    setTopUpAccountNo();
    setCardId("");
    setNote("");
  }

  function renderRestIcon() {
    return (
      <IconButton
        title={intl.formatMessage({ id: "reset" })}
        color="primary"
        style={{ padding: 4, marginRight: 8 }}
        onClick={resetAll}
      >
        <RestartAltIcon />
      </IconButton>
    );
  }

  return (
    <>
      {/* <SelectSeller /> */}
      <div>
        <ReportHeader />
        <Grid container justifyContent="center" spacing={1} paddingTop={"4px"}>
          <Grid
            item
            md={12}
            xs={12}
            container
            spacing={1}
            className={classes.gridContainer}
          >
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              className="report-dropdown-container"
            >
              <Dropdown
                className="report-dropdown"
                options={transStatusOptions}
                onChange={(value) => setTransStatus(value)}
                value={transStatus}
                key={transStatus.value}
                disabled={disableAttr}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              className="report-dropdown-container"
            >
              <Dropdown
                className="report-dropdown"
                options={options}
                onChange={(value) => setTransType(value)}
                value={transType}
                key={transType.value}
                disabled={disableAttr}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              className="report-dropdown-container"
            >
              <Dropdown
                options={companies}
                value={provider}
                key={provider.value}
                className="report-dropdown"
                onChange={(value) => setProvider(value)}
                disabled={disableAttr}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              sm={6}
              className="report-dropdown-container"
            >
              <Dropdown
                options={cardTypeOptions}
                value={cardType}
                key={cardType.value}
                className="report-dropdown"
                onChange={(value) => setCardType(value)}
                disabled={disableAttr}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <CancelableInput
                onChange={(element) => setPhone(element.target.value)}
                className="form-control report-input-left-column"
                placeholder={intl.formatMessage({ id: "Mobile No." })}
                onClearClick={(e) => setPhone("")}
                value={phone}
                disabled={disableAttr}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <CancelableInput
                onClearClick={(e) => {
                  setTransId("");
                }}
                value={transId}
                onChange={(element) => setTransId(element.target.value)}
                style={{ marginTop: 0 }}
                className="form-control report-input-left-column"
                placeholder={intl.formatMessage({ id: "movmentNo" })}
                disabled={disableAttr}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <CancelableInput
                onChange={(element) => setCardId(element.target.value)}
                className="form-control report-input-left-column"
                placeholder={intl.formatMessage({ id: "card_id" })}
                disabled={disableAttr}
                onClearClick={(e) => setCardId("")}
                value={cardId}
                style={{ marginTop: 0 }}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={6}>
              <CancelableInput
                onChange={(element) => setAmount(element.target.value)}
                className="form-control report-input-left-column"
                placeholder={intl.formatMessage({ id: "amount" })}
                disabled={disableAttr}
                onClearClick={(e) => setAmount("")}
                value={amount}
                style={{ marginTop: 0 }}
              />
            </Grid>
            {conditionalReturn(
              isAdminSeller || isReseller,
              <Grid item md={6} xs={12} sm={6}>
                {renderUserTypesDropdown()}
              </Grid>
            )}
            {conditionalReturn(
              isAdminSeller || isReseller,
              <>
                <Grid item md={3} xs={12} sm={6}>
                  <CancelableInput
                    onChange={(element) => setResellerId(element.target.value)}
                    className="form-control report-input-left-column"
                    placeholder={intl.formatMessage({ id: "reseller_id" })}
                    onClearClick={(e) => setResellerId("")}
                    value={resellerId}
                    disabled={disableAttr}
                  />
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <CancelableInput
                    onChange={(element) => setLocation(element.target.value)}
                    className="form-control report-input-left-column"
                    placeholder={intl.formatMessage({ id: "location" })}
                    onClearClick={(e) => setLocation("")}
                    value={location}
                    disabled={disableAttr}
                  />
                </Grid>
              </>
            )}
            {conditionalReturn(
              isAdminSeller,
              <Grid
                item
                md={3}
                xs={12}
                sm={6}
                className="report-dropdown-container"
              >
                <Select
                  options={formatTopUpAccountsOptionsForSelect(
                    topUpAccounts || []
                  )}
                  value={topUpAccountNo}
                  isClearable
                  className="report-dropdown"
                  onChange={(val) => setTopUpAccountNo(val)}
                  id="topUpDropDown"
                  isDisabled={disableAttr}
                />
                <div className="carrierLabelDiv">
                  <label>{topUpAccountNo?.carrier}</label>
                </div>
              </Grid>
            )}

            <Grid item md={3} xs={12} sm={12}>
              <CancelableInput
                onChange={(element) => setNote(element.target.value)}
                className="form-control report-input-left-column"
                placeholder={intl.formatMessage({ id: "Note" })}
                onClearClick={(e) => setNote("")}
                value={note}
                disabled={disableAttr}
              />
            </Grid>

            <Grid item md={3} xs={5}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  width: "100%",
                }}
              >
                <DatePicker
                  selected={dateFrom}
                  type="date"
                  dateFormat="dd-MM-yyyy"
                  placeholderText={intl.formatMessage({ id: "dd-MM-yyyy" })}
                  className={"date-picker form-control"}
                  disabled={disableAttr}
                  onChange={(e) => setDateFrom(e)}
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 16,
                    height: 32,
                    marginLeft: 4,
                    position: "absolute", // Adjust the margin as needed
                    right: 5,
                  }}
                >
                  <ArrowForwardIcon style={{ fontSize: 16 }} />
                </span>
              </div>
            </Grid>
            <Grid
              item
              md={3}
              xs={5}
              // paddingLeft={"0 !important"}
              // paddingRight={2}
              // paddingTop={8}
            >
              <DatePicker
                selected={dateTo}
                type="date"
                dateFormat="dd-MM-yyyy"
                placeholderText={intl.formatMessage({ id: "dd-MM-yyyy" })}
                className={"date-picker form-control"}
                disabled={disableAttr}
                onChange={(e) => setDateTo(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <section className={classNames("report-form-control")}>
          <div className="report-form-control third-row d-flex justify-content-between">
            <div
              className="report-form-control section-one d-flex align-items-center"
              style={{
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              <div className="report-checkbox-container">
                <Checkbox
                  className="report-checkbox"
                  onClick={() => setCancelRequests(!cancelRequest)}
                  checked={cancelRequest}
                  color="primary"
                  id="cancel-request"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  disabled={disableAttr}
                />
                <label htmlFor="cancel-request">
                  {translate("cancel request")}
                </label>
              </div>
              <div className="report-checkbox-container">
                <Checkbox
                  className="report-checkbox"
                  onClick={() => setCanceled(!canceled)}
                  checked={canceled}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  id="canceled"
                  disabled={disableAttr}
                />
                <label htmlFor="canceled">{translate("canceled")}</label>
              </div>
              <div className="report-checkbox-container">
                <Checkbox
                  className="report-checkbox"
                  onClick={() => setAutoRenew(!renew)}
                  checked={renew}
                  color="primary"
                  id="autorenew"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  disabled={disableAttr}
                />
                <label htmlFor="autorenew">{translate("autorenew")}</label>
              </div>
              {isAdminSeller && (
                <div className="report-checkbox-container">
                  <Checkbox
                    className="report-checkbox"
                    onClick={() => setAutoRun(!autoRun)}
                    checked={autoRun}
                    color="primary"
                    id="autorun"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    disabled={isAdminSearching}
                  />
                  <label htmlFor="autorun">{translate("Auto run")}</label>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ height: "32px !important" }} className="">
                {renderRestIcon()}
              </div>

              <div
                style={{
                  height: "32px !important",
                  // marginTop: "32px",
                }}
              >
                {isAdminSeller && autoRun ? (
                  <button
                    className="btn sign-but report-search-btn"
                    style={{ height: "32px" }}
                    id="report-search-btn"
                    onClick={() => handleAdminSearch()}
                  >
                    {isAdminSearching ? "Stop" : "Start"}
                  </button>
                ) : (
                  <Button
                    id="report-search-btn"
                    className="btn sign-but report-search-btn"
                    style={{
                      borderRadius: 4,
                      height: "32px",
                      minHeight: "unset",
                    }}
                    onClick={() => handleSearch()}
                    loading={loading}
                    title="null"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </section>
        <Grid container paddingTop={1} spacing={2} mt={2} mb={2}>
          {conditionalReturn(
            !!sellerReports?.length,
            [
              {
                label: translate("Total Sales"),
                value: sellerReports[0]?.total_sales || 0,
              },
              {
                label: translate("Total Count"),
                value: sellerReports[0]?.total_count || 0,
              },
              {
                label: translate("Total Wholesales"),
                value: sellerReports[0]?.total_wholesales || 0,
              },
              {
                label: translate("Total Profits"),
                value: sellerReports[0]?.total_profit || 0,
              },
            ].map((x, i) => (
              <Grid item xs={12} sm={6} lg={3} key={i}>
                <Typography>
                  {x.label} : <b>{x.value}</b>
                </Typography>
              </Grid>
            ))
          )}
        </Grid>
        <div
          style={{ height: "32px !important", marginTop: "16px" }}
          className=""
        ></div>

        {renderSaleReportTable()}
        {/* {sellerReports?.length > 0 && <Pagination count={count} page={page} handleOffsetValue={handleOffsetValue} handleSortChange={handleSortChange} sort={sort} />} */}
        <Modal
          visible={updateTransactionModal}
          onClose={() => setUpdateTransactionModal(false)}
          title="Update Transaction"
          width="400px"
          onSuccess={async () => {
            await updateTransaction(updateTransactionData);
            setUpdateTransactionModal(false);
            getReportData(0);
          }}
          onSuccessLoading={updateTransactionLoading}
        >
          <div
            style={{
              height: "150px",
              marginTop: "26px",
            }}
          >
            <Select
              options={transactionOptions}
              value={updateTransactionStatus}
              className="report-dropdown"
              onChange={(event) => {
                setUpdateTransactionData({
                  ...updateTransactionData,
                  value: event.value,
                });
                setUpdateTransactionStatus(event);
              }}
              isDisabled={disableAttr}
              styles={{ height: "32px", ...customStyles }}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sellerReports: state.reports.sellerReports,
  discounts: state.discounts.discounts,
  gameArray: state.games.gamesArray,
  auth: state.auth,
  sellers: state.credits.sellers,
  isDarktheme: state.darkTheme.isDarktheme,
  updateTransactionLoading: state.reports.updateTransactionLoading,
});

export default connect(mapStateToProps, {
  getSellerReports,
  getDiscounts,
  getGamingCards,
  getSellers,
  updateTransaction,
})(Report);

import React, { useState, useMemo } from "react";
import { useMutation } from "react-query";
import { useIntl } from "react-intl";
import Notiflix from "notiflix";

import { Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ApiRequest from "../../actions/ApiRequest";

import PageTitle from "../common/PageTitle";
import TextFieldGroup from "components/common/TextFieldGroup";
import PayBillModal from "./PayBillModal";
import { paltelImage } from "assests/images/bundles/paltel/paltelImage";
import { getPaltelBillsApiCalled } from "shared/endPoints/apiCallers";
import translate from "i18n/translate";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { set } from "lodash";
import EditableTextFieldForm from "./shared/InputBills";

const PaltelBills = () => {
  const [number, setNumber] = useState("");
  const [identity, setIdentity] = useState("");
  const [selectedBill, setSelectedBill] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPills, setShowPills] = useState([]);
  const history = useHistory();

  const intl = useIntl();

  useEffect(() => {
    const { state } = history.location;
    if (state?.number) {
      setNumber(state.number);
      setIdentity(state.identity);
      getPaltelBills({ id: state.identity, number: state.number });
    }
  }, []);

  const {
    isLoading,
    mutate: getPaltelBills,
    data: paltelPills,
    reset,
  } = useMutation(getPaltelBillsApiCalled, {
    onSuccess: (res) => {
      if (res?.status === "failed") {
        const errorReason = res?.reason ?? "Something went wrong";
        setErrorMessage(errorReason);
        return;
      }
      ApiRequest.post(
        `/restore_ab_company?company=paltel&session_id=${res[0].session_id}`
      );
    },
    onError: () => {
      Notiflix.Notify.failure(`Something went wrong !`, "", "Close");
    },
  });
  useEffect(() => {
    if (paltelPills?.length > 0) {
      setShowPills(paltelPills);
    }
  }, [paltelPills]);

  const onSubmit = async (value) => {
    setNumber(value);
    setSelectedBill(null);
    setShowPills([]);
    getPaltelBills({ id: identity, number: value });
  };

  const handleNumberChange = (e) => {
    const { value } = e.target;
    setNumber(value);

    // if (value.length === 9) {
    // verifyPaltelNumber({ number: value });
    // }
  };

  const clearSelections = () => {
    setSelectedBill(null);
    reset();
  };

  return (
    <div>
      <PageTitle title={translate("Paltel Bills")} backPage="/pay_bills">
        <img
          src={paltelImage}
          alt="paltel"
          className="paltel-image"
          style={{ width: 150, height: 150, marginTop: 20 }}
        />
        <EditableTextFieldForm
          initialValue={number}
          label={translate("paltel_number")}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />

        {showPills?.length > 0 && (
          <>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <p>
                {translate("Total Bills Amount")}:{""}
                <span className="text-info">
                  {selectedBill?.amount ?? 0} {translate("ILS")}
                </span>
              </p>
              <PayBillModal
                selectedBill={selectedBill}
                number={number}
                isDisabled={!selectedBill?.amount || isLoading}
                clearSelections={clearSelections}
              />
            </div>
            <table className="table table-striped mt-4" id="paltel-bills-table">
              <thead id="paltel-bills-table-head">
                <tr>
                  <th>{translate("Ref")}</th>
                  <th>{translate("Date")}</th>
                  <th>{translate("Must Pay")}</th>
                  <th>{translate("Amount")}</th>
                  <th>{translate("Pay")}</th>
                </tr>
              </thead>
              <tbody id="paltel-bills-table-body">
                {paltelPills?.map((bill, i) => (
                  <tr key={i}>
                    <td>{bill.ref}</td>
                    <td>{bill.date}</td>
                    <td>{bill.must_paid}</td>
                    <td>{bill.amount}</td>
                    <td>
                      <Checkbox
                        color="success"
                        sx={{
                          padding: 0,
                        }}
                        disabled={isLoading}
                        onChange={() =>
                          bill.amount === selectedBill?.amount
                            ? setSelectedBill(null)
                            : setSelectedBill(bill)
                        }
                        checked={
                          Number(bill.amount.split(" ")[0]) <=
                          selectedBill?.amount?.split(" ")?.[0]
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {paltelPills?.length === 0 && <h5>No Results Found</h5>}
        {errorMessage && <h5 className="text-danger">{errorMessage}</h5>}
      </PageTitle>
    </div>
  );
};

export default PaltelBills;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import humanizeDuration from "humanize-duration";
import Countdown from "react-countdown";
import classnames from "classnames";
import ReCAPTCHA from "reaptcha";
import TextFieldGroup from "../common/TextFieldGroup";
import VerificationInput from "react-verification-input";

import Logo from "../../assests/images/logo/black-logo.svg";
import whiteLogo from "../../assests/images/logo/white-logo.svg";

import translate from "../../i18n/translate";
import { VerifyNumber, ConfirmNumber } from "./../../actions/userAction";
import MyButton from "../common/Button";
import UserAlreadyExistsModal from "./UserAlreadyExistsModal";
import Settings from "components/ui/Settings/Settings";

const NumberVerification = ({
  VerifyNumber,
  verifiedNumberLoading,
  verifiedNumber,
  confirmedNumber,
  confirmerNumberLoading,
  ConfirmNumber,
  confirmedNumberError,
  isDarktheme,
}) => {
  const IP = localStorage.getItem("ip");
  const history = useHistory();
  const intl = useIntl();

  const [mobile, setMobile] = useState(null);
  const [code, setCode] = useState("");
  const [timer, setTimer] = useState(180000);
  const [verifyRecaptcha, setVerifyRecaptcha] = useState(false);
  const [refCode, setRefCode] = useState("");
  const [usedNo, setUsedNo] = useState("");

  useEffect(() => {
    if (confirmedNumber) {
      history.push({
        pathname: "/signup",
        state: {
          mobile: mobile,
          refCode,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNumber]);

  useEffect(() => {
    if (history.location.search) {
      setRefCode(history.location.search.split("=")[1]);
    }
  }, []);

  const handleConfirmation = (e) => {
    e.preventDefault();
    if (!verifiedNumber || code.length !== 6 || !verifyRecaptcha) {
      return;
    }
    ConfirmNumber(mobile, IP, code);
  };

  const _updateTimer = (props) => {
    setTimer(props.total);
  };
  const _resendCode = () => {
    if (timer === 0) {
      setTimer(180000);
      VerifyNumber(mobile, IP);
    }
  };

  return (
    <>
      <Settings isAuthenticated={false} />
      <UserAlreadyExistsModal
        open={usedNo?.length}
        usedNumber={usedNo}
        onClose={() => {
          setUsedNo("");
        }}
      />
      <section className={!isDarktheme ? "auth signup" : "auth dark-signup"}>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="card-wrapper">
              <div className="card fat" style={{ height: 620 }}>
                <div className="card-body">
                  <div className="brand">
                    <img src={!isDarktheme ? Logo : whiteLogo} alt="logo"></img>
                  </div>
                  <h4 className="card-title text-center">
                    {translate("Create your free account")}
                  </h4>
                  <h6 className="card-subtitle text-center">
                    {translate("Already have an account?")}{" "}
                    <a href="/signin">{translate("Sign in")}</a>
                  </h6>
                  <div
                    className="signup-validation"
                    //  onSubmit={(e) => onSubmit(e)}
                  >
                    <form
                      onSubmit={async (e) => {
                        e.preventDefault();
                        try {
                          const res = await VerifyNumber(mobile, IP);

                          if (!isNaN(res.toString())) {
                            setUsedNo(res);
                          }
                        } catch (error) {}
                      }}
                    >
                      <TextFieldGroup
                        placeholder={intl.formatMessage({
                          id: "Enter your mobile number",
                        })}
                        name="mobile"
                        type="number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        // error={errors1.mobile}
                        label={translate("Mobile Number")}
                        required={true}
                        disabled={!verifiedNumber}
                      />

                      {!verifiedNumber && (
                        <div className="form-group mb-0 mt-4 actions">
                          <MyButton
                            title="Verify Number"
                            className="btn btn-primary"
                            formType="submit"
                            style={{ width: 240, height: 38 }}
                            loading={verifiedNumberLoading}
                          />
                        </div>
                      )}
                    </form>

                    {verifiedNumber && (
                      <h6 className="card-subtitle text-center text-muted pb-0">
                        <Button
                          variant="link"
                          className="py-0 px-1"
                          onClick={_resendCode}
                          disabled={timer !== 0}
                        >
                          {translate("Resend code")}
                        </Button>
                        <Countdown
                          date={Date.now() + timer}
                          onTick={_updateTimer}
                          onComplete={_updateTimer}
                          renderer={(props) => (
                            <span
                              className={classnames({ hidden: timer === 0 })}
                            >
                              (
                              {humanizeDuration(props.total, {
                                language: intl.locale,
                                units: ["m", "s"],
                              })}
                              )
                            </span>
                          )}
                        />
                      </h6>
                    )}

                    {verifiedNumber && (
                      <form onSubmit={handleConfirmation}>
                        <div>
                          <VerificationInput
                            autoFocus={true}
                            placeholder="-"
                            value={code}
                            onChange={(str) => setCode(str)}
                            classNames={{
                              container: "container-input",
                              character: "character",
                              characterInactive: "character--inactive",
                              characterSelected: "character--selected blink",
                            }}
                            style={{ direction: "ltr" }}
                          />
                        </div>
                        {confirmedNumberError && (
                          <small
                            className="form-text text-left"
                            style={{ color: "red" }}
                          >
                            {intl.formatMessage({ id: "Invalid Code" })}
                          </small>
                        )}
                        <div className="form-group mb-0 mt-4 actions">
                          <MyButton
                            title="Confirm"
                            className="btn btn-primary"
                            formType="submit"
                            style={{ width: 240, height: 38 }}
                            loading={confirmerNumberLoading}
                            onClick={handleConfirmation}
                            disabled={
                              !verifiedNumber ||
                              code.length !== 6 ||
                              !verifyRecaptcha
                            }
                          />
                        </div>
                        <div className="form-group mb-0 mt-4 actions">
                          <ReCAPTCHA
                            sitekey="6LdXOFoqAAAAAEC_xXmgwuhqbZru24F3F85fVFSS"
                            onVerify={() => setVerifyRecaptcha(true)}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({
  auth: {
    verifiedNumber,
    verifiedNumberLoading,
    confirmedNumber,
    confirmerNumberLoading,
    confirmedNumberError,
  },
  darkTheme: { isDarktheme },
}) => {
  return {
    verifiedNumber,
    verifiedNumberLoading,
    confirmerNumberLoading,
    confirmedNumber,
    confirmedNumberError,
    isDarktheme,
  };
};

export default connect(mapStateToProps, { VerifyNumber, ConfirmNumber })(
  NumberVerification
);
